
@font-face{
  font-family: 'Switzer';
  src: local('Switzer'), url('./fonts/Switzer-Variable.woff') format('woff')
}
@font-face{
  font-family: 'Clash Grotesk Variable';
  src: local('Clash Grotesk Variable'), url('./fonts/ClashGrotesk-Variable.woff') format('woff')
}

body {
  margin: 0;
  font-family: 'Switzer', 'Clash Grotesk Variable', 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div:focus, input:focus{
  outline: 1;
}